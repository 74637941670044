<template>
    <div class="tsos-tooltip">
        <a-tooltip placement="right">
            <template slot="title">
                {{ tooltip }}
            </template>
            <!-- <a-icon type="info-circle" theme="filled"></a-icon> -->
            <a-icon type="info-circle" :style="{color: '#3A0BA3'}"></a-icon>
        </a-tooltip>
    </div>
</template>
<script>
export default {
    props: ['tooltip']
}
</script>
<style>
.tsos-tooltip {
    display: inline-block;
    margin-left: 5px;
}
</style>